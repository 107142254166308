<template>
  <section ref="MainContent" class="relative z-10 w-full py-28">
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10 py-36"
    >
      <div class="relative z-10 mb-20 text-center">
        <p
          data-section-title
          class="uppercase lg:text-[160px] md:text-[140px] sm:text-[100px] text-[70px] font-bold mb-5 leading-none text-primary"
        >
          Contact us
        </p>
        <p
          data-section-subtitle
          class="font-black md:text-2xl sm:text-xl text-secondary"
          v-html="$GetColumn('contact_section_content')"
        ></p>
      </div>
      <div
        data-section-content
        class="relative z-10 flex items-center justify-center w-full"
      >
        <router-link
          data-section-button
          to="/contact"
          class="sm:w-[320px] w-full font-bold p-5 bg-primary text-white inline-block hover:md:text-primary hover:md:bg-light-gray transition-colors duration-200"
        >
          <span
            class="inline-block w-2 h-2 mr-4 text-current bg-current"
          ></span>
          CONTACT NOW
        </router-link>
      </div>

      <div
        data-section-content
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 md:w-[900px] w-2/3 max-w-full md:aspect-[3/2] aspect-[2/3]"
      >
        <img
          :src="$ImageUrl($GetColumn('contact_section_image'))"
          width="900"
          height="600"
          alt="勁祥空調有限公司"
          class="hidden object-cover w-full h-full opacity-60 sm:block"
        />
        <img
          :src="$ImageUrl($GetColumn('contact_section_image_sm'))"
          width="297"
          height="371"
          alt="勁祥空調有限公司"
          class="block object-cover w-full h-full opacity-60 sm:hidden"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { CommonSection } from '@/gsap/CommonSection';
export default {
  name: 'ContactFooter',
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new CommonSection(this.$refs.MainContent);
    this.section_gsap.reset();
  },
};
</script>
