<template>
  <section
    ref="MainContent"
    class="relative w-full pb-10 overflow-hidden sm:pb-40"
  >
    <div
      class="relative flex items-stretch w-full max-w-screen-xl mx-auto mb-5"
    >
      <div
        data-carousel-box
        v-for="(carousel, carousel_index) in carousel_data"
        :key="`carousel_${carousel_index}`"
        class="transform flex-shrink-0 w-full md:px-40 sm:px-20 px-10 sm:pb-[80px] pb-[100px] md:pb-[100px] relative z-10"
      >
        <div class="relative w-full">
          <div
            class="absolute sm:w-3/5 w-full md:-bottom-[100px] sm:-bottom-[80px] -bottom-[100px] md:-left-20 sm:-left-5 left-0 z-20"
          >
            <p
              data-carousel-title
              class="xl:text-7xl lg:text-6xl sm:text-5xl text-[42px] font-black tracking-wider leading-none text-primary uppercase lg:mb-5 mb-2"
            >
              {{ carousel.SubTitle }}
            </p>
            <h4
              data-carousel-subtitle
              class="text-3xl font-black tracking-wider lg:text-5xl text-dark-gray"
            >
              {{ carousel.Title }}
            </h4>
          </div>
          <div
            data-carousel-imagebox
            class="w-full sm:aspect-video aspect-[297/445] relative z-10 overflow-hidden transform"
          >
            <img
              data-carousel-image
              :alt="carousel.Title"
              :src="$ImageUrl(carousel.Image1)"
              width="960"
              height="540"
              class="relative z-10 hidden object-cover w-full h-full sm:block"
            />
            <img
              data-carousel-image
              :alt="carousel.Title"
              :src="$ImageUrl(carousel.Image2)"
              width="334"
              height="500"
              class="relative z-10 block object-cover w-full h-full sm:hidden"
            />
          </div>
        </div>
      </div>
      <div
        data-carousel-square
        class="absolute bottom-0 sm:block hidden w-full max-w-[90%] xl:max-w-screen-xl left-1/2 transform -translate-x-1/2 z-0"
      >
        <div
          class="transform transition-all duration-300 md:w-[180px] w-[100px] h-[100px] md:h-[180px] lg:w-[260px] lg:h-[260px] bg-primary absolute bottom-0 xl:right-20 md:right-10 right-0 z-0"
        ></div>
      </div>
    </div>

    <div
      class="px-[10px] w-full max-w-[90%] mx-auto items-end flex sm:justify-center justify-between"
    >
      <ol class="flex items-center">
        <li
          v-for="(bullet, bullet_index) in carousel_data"
          :key="`sm_bullet_${bullet_index}`"
          class="px-2"
        >
          <button
            data-carousel-bullet
            :aria-label="`切換至第${bullet_index}張`"
            @click="ChangeCarousel(bullet_index)"
            class="w-2 h-2"
          ></button>
        </li>
      </ol>
      <div class="relative z-0 block w-20 h-20 sm:hidden bg-primary"></div>
    </div>
  </section>
</template>

<script>
import { Carousel } from "@/gsap/home/Carousel";
export default {
  name: "HomeCarousel",
  data() {
    return {
      active_carousel_index: 0,
      carousel_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.carousel_gsap.setup();
    },
    ChangeCarousel(index) {
      this.carousel_gsap.changeCarousel(index);
    },
  },
  computed: {
    carousel_data() {
      return this.$store.state.carousel_data;
    },
  },
  mounted() {
    this.carousel_gsap = new Carousel(this.$refs.MainContent);
    this.carousel_gsap.reset();
  },
  created() {
    this.$PreLoadImage(this.$ImageUrl(this.carousel_data[0].Image1));
  },
  beforeDestroy() {
    this.carousel_gsap != null ? this.carousel_gsap.destroy() : "";
  },
};
</script>
