import { gsap } from '@/gsap/GsapLoader';

export class BrandList {
  constructor(el) {
    this.el = el;
    this.section_title = el.querySelector('[data-section-title]');
    this.section_sub_title = el.querySelector('[data-section-subtitle]');
    this.section_content = el.querySelectorAll('[data-section-content]');
    this.section_button = el.querySelector('[data-section-button]');

    this.section_timeline = null;
  }

  reset() {
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;

    gsap.set(this.section_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_sub_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_content, { opacity: 0, y: '20px' });
    gsap.set(this.section_button, { opacity: 0, y: '20px' });
  }

  setup() {
    this.getSectionTl();
  }

  getSectionTl() {
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;
    this.section_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.section_timeline.fromTo(
      this.section_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_sub_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_content,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_button,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
  }
}
