import { gsap } from '@/gsap/GsapLoader';

export class CoreValue {
  constructor(el) {
    this.el = el;
    this.section_title = el.querySelector('[data-section-title]');
    this.section_sub_title = el.querySelector('[data-section-subtitle]');
    this.section_content = el.querySelector('[data-section-content]');
    this.section_button = el.querySelector('[data-section-button]');
    //
    this.card_list = el.querySelector('[data-card-list]');
    this.card_box = el.querySelectorAll('[data-card-box]');
    this.card_title = el.querySelectorAll('[data-card-box] [data-card-title]');
    this.card_sub_title = el.querySelectorAll(
      '[data-card-box] [data-card-subtitle]'
    );
    this.card_line = el.querySelectorAll('[data-card-box] [data-card-line]');
    this.card_content = el.querySelectorAll(
      '[data-card-box] [data-card-content]'
    );
    this.card_image = el.querySelectorAll('[data-card-box] [data-card-image]');

    this.section_timeline = null;
    this.card_timeline = null;
  }

  reset() {
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;
    this.card_timeline != null ? this.card_timeline.kill() : '';
    this.card_timeline = null;

    gsap.set(this.section_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_sub_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_content, { opacity: 0, y: '20px' });
    gsap.set(this.section_button, { opacity: 0, y: '20px' });

    gsap.set(this.card_title, { opacity: 0, y: '10px' });
    gsap.set(this.card_line, { scaleX: 0, transformOrigin: 'left' });
    gsap.set(this.card_sub_title, { opacity: 0, blur: 5 });
    gsap.set(this.card_content, { opacity: 0, blur: 10 });
    gsap.set(this.card_image, { scale: 0 });
  }

  setup() {
    this.getSectionTl();
    this.getCardTl();
  }

  getSectionTl() {
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;
    this.section_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.section_timeline.fromTo(
      this.section_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_sub_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_content,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_button,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
  }

  getCardTl() {
    this.card_timeline != null ? this.card_timeline.kill() : '';
    this.card_timeline = null;
    this.card_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.card_list,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.card_timeline.fromTo(
      this.card_title,
      {
        opacity: 0,
        y: '10px',
      },
      {
        opacity: 1,
        y: '0px',
      },
      'title'
    );
    this.card_timeline.fromTo(
      this.card_line,
      {
        scaleX: 0,
        transformOrigin: 'left',
      },
      {
        scaleX: 1,
        duration: 1,
        transformOrigin: 'left',
      },
      'title'
    );
    this.card_timeline.fromTo(
      this.card_sub_title,
      {
        opacity: 0,
        blur: 5,
      },
      {
        opacity: 1,
        delay: 0.3,
        blur: 0,
      },
      'title'
    );
    this.card_timeline.fromTo(
      this.card_content,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        delay: 0.5,
        blur: 0,
      },
      'title'
    );
    this.card_timeline.fromTo(
      this.card_image,
      {
        scale: 0,
      },
      {
        scale: 1,
        delay: 0.5,
      },
      'title'
    );
  }
}
