<template>
  <div
    id="app"
    class="relative z-10 flex flex-col w-full min-h-screen bg-background"
  >
    <MainLoading />
    <MainHeader @open-menu="OpenMenu" />

    <template v-if="all_data_loaded">
      <MainMenu ref="MainMenu" />
      <MainDialog />
      <div class="relative z-10 flex-1 min-h-screen">
        <router-view
          class="relative z-10 min-h-screen"
          @load-image="LoadImage"
        />
      </div>
      <ContactFooter ref="ContactFooter" />
      <MainFooter />
    </template>
    <div
      class="fixed top-0 bottom-0 left-0 right-0 z-0 bg-repeat main_fixed_bg"
    ></div>
  </div>
</template>

<script>
import { ImageLoader } from '@/gsap/ImageLoader';
import ContactFooter from '@/components/ContactFooter.vue';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainMenu from '@/components/MainMenu.vue';
import MainDialog from '@/components/MainDialog.vue';
import MainLoading from '@/components/MainLoading.vue';
export default {
  name: 'App',
  components: {
    ContactFooter,
    MainFooter,
    MainHeader,
    MainMenu,
    MainDialog,
    MainLoading,
  },
  data() {
    return {
      image_loader: null,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
    CloseMenu() {
      this.$refs.MainMenu.Close();
    },
  },
  watch: {
    '$store.state.image_loaded'() {
      this.$store.state.image_loaded ? this.$refs.ContactFooter.SetGsap() : '';
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
  },
  mounted() {
    this.image_loader = new ImageLoader();
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch('getAllData') : '';
  },
};
</script>

<style src="@/assets/css/output.css"></style>
