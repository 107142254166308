<template>
  <main class="relative z-10 w-full pt-32 sm:pt-40">
    <h1 class="hidden">勁翔空調 首頁</h1>
    <BreadCrumb :path="bread_crumb" />
    <Carousel ref="Carousel" />
    <CoreValue ref="CoreValue" />
    <CaseList ref="CaseList" />
    <BrandList ref="BrandList" />
  </main>
</template>

<script>
import Carousel from '@/components/home/Carousel.vue';
import CoreValue from '@/components/home/CoreValue.vue';
import CaseList from '@/components/home/CaseList.vue';
import BrandList from '@/components/home/BrandList.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import { GetMetaData } from '@/common/meta';

export default {
  name: 'HomePage',
  components: {
    Carousel,
    CoreValue,
    CaseList,
    BrandList,
    BreadCrumb,
  },
  data() {
    return {
      bread_crumb: [
        {
          title: '首頁',
          link: '/',
        },
      ],
      meta_data: null,
    };
  },
  methods: {
    SetGsap() {
      this.$refs.Carousel.SetGsap();
      this.$refs.CoreValue.SetGsap();
      this.$refs.CaseList.SetGsap();
      this.$refs.BrandList.SetGsap();
    },
  },
  watch: {
    '$store.state.image_loaded'() {
      this.$store.state.image_loaded ? this.SetGsap() : '';
    },
  },
  mounted() {
    this.meta_data = GetMetaData('首頁');
    this.$PageReady(this.meta_data.title);
    this.$emit('load-image');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
