<template>
  <header class="fixed top-0 left-0 right-0 z-30 pt-10">
    <div
      class="flex items-center justify-between w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <router-link to="/" aria-label="勁祥空調有限公司">
        <img
          src="/img/logo_black@md.webp"
          width="200"
          height="60"
          alt="勁祥空調有限公司"
          class="w-[200px] sm:block hidden"
        />
        <img
          src="/img/logo_black@sm.webp"
          width="60"
          height="48"
          alt="勁祥空調有限公司"
          class="w-[60px] sm:hidden block"
        />
      </router-link>
      <nav class="hidden lg:block">
        <ol class="flex items-stretch pl-5 bg-white shadow-md">
          <li
            v-for="(item, item_index) in menu_list"
            :class="item_index == menu_list.length - 1 ? 'mr-5' : ''"
            :key="`menu_item_${item_index}`"
          >
            <router-link
              :to="item.link"
              class="block p-5 text-sm font-bold transition-all duration-300 hover:md:text-primary"
            >
              {{ item.title }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/contact"
              class="block px-10 py-5 text-sm font-bold text-white transition-all duration-300 bg-primary hover:md:bg-black"
            >
              聯絡我們
            </router-link>
          </li>
        </ol>
      </nav>
      <div class="flex items-stretch shadow-md lg:hidden">
        <router-link
          to="/contact"
          class="flex items-center py-3 text-sm font-bold text-white transition-all duration-300 sm:px-10 px-7 sm:py-5 bg-primary hover:md:bg-black"
        >
          聯絡我們
        </router-link>
        <button
          @click="$emit('open-menu')"
          aria-label="打開選單"
          class="flex items-center justify-center w-12 transition-colors duration-300 bg-white sm:w-14 aspect-square hover:md:bg-black hover:md:text-white"
        >
          <span class="text-xl icon-menu text-[CurrentColor]"></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  data() {
    return {
      menu_list: [
        {
          title: '公司介紹',
          link: '/about',
        },
        {
          title: 'ESG永續經營',
          link: '/esg',
        },
        {
          title: '服務項目',
          link: '/services',
        },
        {
          title: '工程實績',
          link: '/projects',
        },
        {
          title: '最新消息',
          link: '/news',
        },
      ],
    };
  },
};
</script>
