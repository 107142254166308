import { gsap } from '@/gsap/GsapLoader';

export class CaseList {
  constructor(el) {
    this.el = el;
    this.section_title = el.querySelector('[data-section-title]');
    this.section_sub_title = el.querySelector('[data-section-subtitle]');
    this.section_content = el.querySelector('[data-section-content]');
    this.section_button = el.querySelector('[data-section-button]');
    //
    this.carousel_box = el.querySelectorAll('[data-card-box]');
    this.carousel_bullets = el.querySelectorAll('[data-card-bullet]');

    this.section_timeline = null;
    this.carousel_timeline = null;
    this.timer = null;
    this.active_carousel_index = 0;
  }

  reset() {
    gsap.set(this.section_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_sub_title, { opacity: 0, blur: 10 });
    gsap.set(this.section_content, { opacity: 0, y: '20px' });
    gsap.set(this.section_button, { opacity: 0, y: '20px' });
    //
    this.carousel_box.forEach((box) => {
      const title = box.querySelector('[data-card-title]');
      const image = box.querySelector('[data-card-image]');
      const button = box.querySelector('[data-card-button]');
      gsap.set(box, { x: '0%' });
      gsap.set(title, { opacity: 0.3 });
      gsap.set(image, { opacity: 0.2, blur: 1 });
      gsap.set(button, { opacity: 0.1 });
    });
    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timer = null;
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = null;
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;
    this.active_carousel_index = 0;
  }

  resetCarousel() {
    //
    this.carousel_box.forEach((box) => {
      const title = box.querySelector('[data-card-title]');
      const image = box.querySelector('[data-card-image]');
      const button = box.querySelector('[data-card-button]');
      gsap.set(box, { x: '0%' });
      gsap.set(title, { opacity: 0.3 });
      gsap.set(image, { opacity: 0.2, blur: 1 });
      gsap.set(button, { opacity: 0.1 });
    });
    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timer = null;
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = null;
    this.active_carousel_index = 0;
  }

  setup() {
    this.getSectionTl();
  }

  getSectionTl() {
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = null;
    this.section_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
        onEnter: () => {
          this.resetCarousel();
          this.setupCarousel();
        },
        onEnterBack: () => {
          this.setNextTimer();
        },
        onLeave: () => {
          this.timer != null ? clearTimeout(this.timer) : '';
        },
        onLeaveBack: () => {
          this.timer != null ? clearTimeout(this.timer) : '';
        },
      },
    });

    this.section_timeline.fromTo(
      this.section_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_sub_title,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_content,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
    this.section_timeline.fromTo(
      this.section_button,
      {
        opacity: 0,
        y: '20px',
      },
      {
        opacity: 1,
        y: '0px',
        delay: 0.3,
      },
      'title'
    );
  }

  setupCarousel() {
    const box = this.carousel_box[0];
    const title = box.querySelector('[data-card-title]');
    const image = box.querySelector('[data-card-image]');
    const button = box.querySelector('[data-card-button]');

    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });
    gsap.to(this.carousel_bullets[0], {
      opacity: 1,
      backgroundColor: '#122aa1',
    });

    this.carousel_timeline = gsap.timeline();
    this.carousel_timeline.fromTo(
      image,
      {
        opacity: 0.2,
        blur: 1,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'image'
    );
    this.carousel_timeline.fromTo(
      button,
      {
        opacity: 0.1,
        x: '0px',
        y: '0px',
      },
      {
        opacity: 1,
        x: '5px',
        y: '5px',
      },
      'image'
    );
    this.carousel_timeline.fromTo(
      title,
      {
        opacity: 0.3,
      },
      {
        opacity: 1,
        onComplete: () => {
          this.setNextTimer();
        },
      },
      'text'
    );
  }

  setNextTimer() {
    this.timer = setTimeout(() => {
      let next_index = this.active_carousel_index + 1;
      next_index == this.carousel_box.length ? (next_index = 0) : '';
      this.changeCarousel(next_index);
    }, 3000);
  }

  changeCarousel(index) {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = gsap.timeline({
      onComplete: () => {
        this.setNextTimer();
      },
    });
    // out now active carousel
    this.carousel_timeline.add(this.getCarouselOutTl(), 'out');
    // move carousel
    this.active_carousel_index = index;
    this.carousel_timeline.to(
      this.carousel_box,
      {
        x: `${-100 * this.active_carousel_index}%`,
      },
      'move'
    );

    this.carousel_timeline.add(this.getCarouselInTl(), 'in');
  }

  getCarouselOutTl() {
    let out_timeline = gsap.timeline();
    const box = this.carousel_box[this.active_carousel_index];
    const title = box.querySelector('[data-card-title]');
    const image = box.querySelector('[data-card-image]');
    const button = box.querySelector('[data-card-button]');

    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });

    out_timeline.fromTo(
      title,
      {
        opacity: 1,
      },
      {
        opacity: 0.3,
      },
      'image'
    );
    out_timeline.fromTo(
      image,
      {
        opacity: 1,
        blur: 0,
      },
      {
        opacity: 0.2,
        blur: 1,
      },
      'image'
    );
    out_timeline.fromTo(
      button,
      {
        opacity: 1,
        x: '5px',
        y: '5px',
      },
      {
        opacity: 0.1,
        x: '0px',
        y: '0px',
      },
      'image'
    );

    return out_timeline;
  }

  getCarouselInTl() {
    let in_timeline = gsap.timeline();
    const box = this.carousel_box[this.active_carousel_index];
    const title = box.querySelector('[data-card-title]');
    const image = box.querySelector('[data-card-image]');
    const button = box.querySelector('[data-card-button]');

    gsap.to(this.carousel_bullets[this.active_carousel_index], {
      opacity: 1,
      backgroundColor: '#122aa1',
    });

    in_timeline.fromTo(
      image,
      {
        opacity: 0.2,
        blur: 1,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'image'
    );
    in_timeline.fromTo(
      button,
      {
        opacity: 0.1,
        x: '0px',
        y: '0px',
      },
      {
        opacity: 1,
        x: '5px',
        y: '5px',
      },
      'image'
    );
    in_timeline.fromTo(
      title,
      {
        opacity: 0.3,
      },
      {
        opacity: 1,
      },
      'image'
    );

    return in_timeline;
  }

  destroy() {
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = null;
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timer = null;
  }
}
