<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full py-24 overflow-hidden md:py-40"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <header class="relative w-full mb-32 text-center">
        <div class="relative z-10 flex flex-col-reverse items-center mb-10">
          <h2
            data-section-subtitle
            class="text-[40px] font-black text-secondary"
          >
            合作企業
          </h2>
          <p
            data-section-title
            class="text-2xl font-bold uppercase text-primary"
          >
            Related business
          </p>
        </div>
        <div
          data-section-content
          class="relative z-10 font-bold text-secondary"
          v-html="
            $GetColumn('collaborate_section_content').replace(/\n/g, '<br />')
          "
        ></div>
      </header>
      <div class="relative z-10 w-full">
        <ol
          data-section-content
          class="relative z-10 flex flex-wrap items-stretch"
        >
          <li
            v-for="(brand, brand_index) in brand_data"
            :key="`brand_${brand_index}`"
            :class="GetBorderStyle(brand_index + 1)"
            class="relative z-10 w-1/2 py-10 md:w-1/4 sm:w-1/3 border-dark-gray"
          >
            <a :href="brand.Link" target="_blank" class="block w-full">
              <img
                :src="$ImageUrl(brand.Image1)"
                :alt="brand.Title"
                class="block w-2/3 mx-auto"
                width="320"
                height="320"
              />
            </a>
          </li>
        </ol>

        <img
          src="/img/home/related_business_text.webp"
          width="758"
          height="279"
          alt="勁祥空調 合作企業"
          class="absolute sm:block hidden top-0 left-1/2 transform -translate-x-1/2 md:w-[758px] w-4/5 max-w-full opacity-5 md:-translate-y-14 translate-y-20"
        />

        <img
          src="/img/home/related_business_text@sm.webp"
          width="445"
          height="164"
          alt="勁祥空調 合作企業"
          class="absolute block w-full transform -translate-x-1/2 -translate-y-1/2 sm:hidden top-1/2 left-1/2 opacity-5"
        />
        <!-- line -->
        <div
          data-section-content
          class="absolute top-0 bottom-0 left-0 right-0 z-0"
        >
          <i
            v-if="brand_data.length > 4"
            class="md:block hidden w-full h-[1px] bg-dark-gray absolute top-1/2 left-0 right-0 transform -translate-y-1/2"
          ></i>
          <i
            v-if="brand_data.length > 3"
            :class="
              brand_data.length > 6
                ? '-translate-y-1/3 top-1/3'
                : 'top-1/2 -translate-y-1/2'
            "
            class="md:hidden sm:block hidden w-full h-[1px] bg-dark-gray absolute left-0 right-0 transform"
          ></i>
          <i
            v-if="brand_data.length > 6"
            class="md:hidden sm:block hidden w-full h-[1px] bg-dark-gray absolute top-2/3 left-0 right-0 transform -translate-y-2/3"
          ></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BrandList } from "@/gsap/home/BrandList";
export default {
  name: "HomeCoreValueSection",
  data() {
    return {
      brand_list_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.brand_list_gsap.setup();
    },
    GetBorderStyle(index) {
      let style_class = "";
      if (index == 1) {
        style_class = "border-r  ";
      }
      // md以上
      if (index % 4 != 0) {
        style_class += "md:border-r  ";
      } else {
        style_class += "md:border-r-0 ";
      }
      // sm以上
      if (index % 3 != 0) {
        style_class += "sm:border-r ";
      } else {
        style_class += "sm:border-r-0 ";
      }
      // 手機
      if (index % 2 != 0) {
        style_class += "border-r ";
      }
      if (
        index != this.brand_data.length &&
        index != this.brand_data.length - 1
      ) {
        style_class += "sm:border-b-0 border-b ";
      }
      return style_class;
    },
  },
  computed: {
    brand_data() {
      return this.$store.state.collaborate_data;
    },
  },
  mounted() {
    this.brand_list_gsap = new BrandList(this.$refs.MainContent);
    this.brand_list_gsap.reset();
  },
};
</script>
