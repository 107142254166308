import { gsap } from '@/gsap/GsapLoader';

export class Carousel {
  constructor(el) {
    this.carousel_box = el.querySelectorAll('[data-carousel-box]');
    this.carousel_bullets = el.querySelectorAll('[data-carousel-bullet]');
    this.timeline = null;
    this.timer = null;
    this.active_carousel_index = 0;

    window.addEventListener('resize', () => {
      this.getCarouselMoveTl();
    });
  }

  reset() {
    this.carousel_box.forEach((box) => {
      const title = box.querySelector('[data-carousel-title]');
      const sub_title = box.querySelector('[data-carousel-subtitle]');
      const image = box.querySelector('[data-carousel-image]');
      gsap.set(title, { opacity: 0, y: 20 });
      gsap.set(sub_title, { opacity: 0, y: 20 });
      gsap.set(image, { opacity: 0.2, blur: 1 });
    });
    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timer = null;
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
    this.active_carousel_index = 0;
  }

  setup() {
    const box = this.carousel_box[0];
    const title = box.querySelector('[data-carousel-title]');
    const sub_title = box.querySelector('[data-carousel-subtitle]');
    const image = box.querySelector('[data-carousel-image]');

    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });
    gsap.to(this.carousel_bullets[0], {
      opacity: 1,
      backgroundColor: '#122aa1',
    });

    this.timeline = gsap.timeline();
    this.timeline.add(this.getCarouselMoveTl(), 'image');
    this.timeline.fromTo(
      image,
      {
        opacity: 0.2,
        blur: 1,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'image'
    );
    this.timeline.fromTo(
      title,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      'text'
    );
    this.timeline.fromTo(
      sub_title,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.3,
        onComplete: () => {
          this.setNextTimer();
        },
      },
      'text'
    );
  }

  setNextTimer() {
    this.timer = setTimeout(() => {
      let next_index = this.active_carousel_index + 1;
      next_index == this.carousel_box.length ? (next_index = 0) : '';
      this.changeCarousel(next_index);
    }, 3000);
  }

  changeCarousel(index) {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      onComplete: () => {
        this.setNextTimer();
      },
    });
    // out now active carousel
    this.timeline.add(this.getCarouselOutTl(), 'out');
    // move carousel
    this.active_carousel_index = index;
    this.timeline.to(
      this.carousel_box,
      {
        x: `${-100 * this.active_carousel_index}%`,
      },
      'move'
    );
    this.timeline.add(this.getCarouselMoveTl(), 'move');

    this.timeline.add(this.getCarouselInTl(), 'in');
  }

  getCarouselOutTl() {
    let out_timeline = gsap.timeline();
    const box = this.carousel_box[this.active_carousel_index];
    const title = box.querySelector('[data-carousel-title]');
    const sub_title = box.querySelector('[data-carousel-subtitle]');
    const image = box.querySelector('[data-carousel-image]');

    gsap.set(this.carousel_bullets, {
      opacity: 0.5,
      backgroundColor: '#666666',
    });

    out_timeline.fromTo(
      title,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: 20,
      },
      'text'
    );
    out_timeline.fromTo(
      sub_title,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: 20,
      },
      'text'
    );
    out_timeline.fromTo(
      image,
      {
        opacity: 1,
        blur: 0,
      },
      {
        opacity: 0.2,
        blur: 1,
      },
      'image'
    );

    return out_timeline;
  }

  getCarouselMoveTl() {
    let move_timeline = gsap.timeline();
    let window_size_list = {
      1280: '160px',
      768: '200px',
      640: '120px',
    };
    let move_des = '';

    if (window.innerWidth < 640) {
      move_des = '60px';
    } else {
      Object.keys(window_size_list).forEach((item) => {
        window.innerWidth > item ? (move_des = window_size_list[item]) : '';
      });
    }

    const image_box = this.carousel_box[
      this.active_carousel_index
    ].querySelector('[data-carousel-imagebox]');
    move_timeline.to(
      image_box,
      {
        x: `0px`,
      },
      'move'
    );

    if (this.active_carousel_index != 0) {
      const image_box = this.carousel_box[
        this.active_carousel_index - 1
      ].querySelector('[data-carousel-imagebox]');
      move_timeline.to(
        image_box,
        {
          x: move_des,
        },
        'move'
      );
    }
    if (this.active_carousel_index != this.carousel_box.length - 1) {
      const image_box = this.carousel_box[
        this.active_carousel_index + 1
      ].querySelector('[data-carousel-imagebox]');
      move_timeline.to(
        image_box,
        {
          x: '-' + move_des,
        },
        'move'
      );
    }

    return move_timeline;
  }

  getCarouselInTl() {
    let in_timeline = gsap.timeline();
    const box = this.carousel_box[this.active_carousel_index];
    const title = box.querySelector('[data-carousel-title]');
    const sub_title = box.querySelector('[data-carousel-subtitle]');
    const image = box.querySelector('[data-carousel-image]');

    gsap.to(this.carousel_bullets[this.active_carousel_index], {
      opacity: 1,
      backgroundColor: '#122aa1',
    });

    in_timeline.fromTo(
      image,
      {
        opacity: 0.2,
        blur: 1,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'image'
    );
    in_timeline.fromTo(
      title,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      'text'
    );
    in_timeline.fromTo(
      sub_title,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.3,
      },
      'text'
    );

    return in_timeline;
  }

  destroy() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timer = null;
    window.removeEventListener(
      'resize',
      () => {
        this.getCarouselMoveTl();
      },
      true
    );
  }
}
