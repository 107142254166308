import { post, get } from '@/common/request';

export function getAllData() {
  return get('/homepage');
}

export function sendContact({ name, phone, time, comment }) {
  let data = {
    Name: name,
    Phone: phone,
    Time: time,
    Comment: comment,
  };
  return post('contactUs', data);
}
