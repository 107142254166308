<template>
  <footer
    class="relative z-10 w-full pt-20 pb-10 border-t border-dark-gray border-opacity-30"
  >
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10">
      <div class="mb-20">
        <router-link to="/" aria-label="勁祥空調有限公司">
          <img
            src="/img/logo_black@md.webp"
            alt="勁祥空調有限公司"
            width="270"
            height="85"
            class="w-[270px]"
          />
        </router-link>
      </div>
      <div class="flex flex-wrap items-stretch justify-between w-full mb-28">
        <div class="w-full mb-20 sm:w-1/2 sm:mb-0">
          <p class="mb-5 text-2xl font-bold text-primary">MENU</p>
          <ol class="flex flex-wrap w-full">
            <li
              v-for="(item, item_index) in menu_list"
              :key="`menu_item_${item_index}`"
              class="w-1/2 mb-3 sm:w-full md:w-1/2"
            >
              <router-link
                :to="item.link"
                class="block w-full text-sm font-bold text-primary hover:md:opacity-60"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ol>
        </div>
        <div class="w-full sm:w-1/2">
          <p class="mb-5 text-2xl font-bold text-primary">CONTACT US</p>
          <div class="flex flex-wrap items-end justify-between md:flex-nowrap">
            <ol class="w-full">
              <li class="mb-3">
                <p class="text-sm font-bold text-dark-gray">
                  電子郵件<span class="ml-3">{{
                    $GetColumn("company_email")
                  }}</span>
                </p>
              </li>
              <li class="mb-3">
                <p class="text-sm font-bold text-dark-gray">
                  聯絡電話<span class="ml-3">{{
                    $GetColumn("company_phone")
                  }}</span>
                </p>
              </li>
              <li class="mb-3">
                <p class="text-sm font-bold text-dark-gray">
                  傳真電話<span class="ml-3">{{
                    $GetColumn("company_fax")
                  }}</span>
                </p>
              </li>
              <li class="mb-3">
                <p class="text-sm font-bold text-dark-gray">
                  公司地址<span class="ml-3">{{
                    $GetColumn("company_address")
                  }}</span>
                </p>
              </li>
            </ol>
            <img
              :src="$ImageUrl($GetColumn('company_line_image'))"
              width="112"
              height="112"
              alt="勁祥空調LINE@"
              class="flex-shrink-0 block w-28 h-28"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center justify-end w-full pt-3 border-t border-opacity-30 border-dark-gray"
      >
        <p class="text-sm text-dark-gray">
          Copyright © 2023 勁祥空調有限公司. All Rights Reserved.
        </p>
        <p class="pl-2 text-sm text-dark-gray">
          網頁設計
          <a
            target="_blank"
            href="https://www.yongxin-design.com/"
            class="font-medium text-primary"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "公司介紹",
          link: "/about",
        },
        {
          title: "ESG永續經營",
          link: "/esg",
        },
        {
          title: "服務項目",
          link: "/services",
        },
        {
          title: "工程實績",
          link: "/projects",
        },
        {
          title: "最新消息",
          link: "/news",
        },
        {
          title: "影音相簿",
          link: "/videos",
        },
        {
          title: "生活花絮",
          link: "/trivia",
        },
        {
          title: "聯絡我們",
          link: "/contact",
        },
      ],
    };
  },
};
</script>
