import Vue from 'vue';
import store from '@/store';
import router from '@/router';

Vue.prototype.$ImageUrl = (item) => {
  return item == '' ? '' : process.env.VUE_APP_BASE_API + item;
};

Vue.prototype.$GetColumn = (key) => {
  const column = store.state.common_column_data.filter(
    (column) => column.Title == key
  );
  return column.length <= 0 ? '' : column[0].Content;
};

Vue.prototype.$MoneyFormat = (price) => {
  let val = (price / 1).toFixed(0).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Vue.prototype.$PageReady = (title) => {
  const image_el = document.querySelectorAll('img');
  image_el.forEach((item) => {
    item.alt != '' ? '' : (item.alt = Vue.prototype.$GetColumn('company_name'));
    item.getAttribute('width')
      ? ''
      : item.setAttribute('width', item.clientWidth);
    item.getAttribute('height')
      ? ''
      : item.setAttribute('height', item.clientHeight);
  });

  CheckFontLoad();
  window.prerenderReady = true;
  // window.dataLayer.push
  console.log({
    event: 'page_view',
    page_title: title,
  });
};

Vue.prototype.$RedirectError = () => {
  var meta = document.createElement('meta');
  meta.setAttribute('property', 'prerender-status-code');
  meta.setAttribute('name', 'prerender-status-code');
  meta.content = '404';
  document.getElementsByTagName('head')[0].appendChild(meta);
  router.push('/error_page');
};

Vue.prototype.$LoadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

Vue.prototype.$PreLoadImage = (src) => {
  let image_link = document.createElement('link');
  image_link.as = 'image';
  image_link.rel = 'preload';
  image_link.href = src;
  document.head.appendChild(image_link);
};

Vue.prototype.$LoadAllFont = () => {
  LoadAllFont();
};

function LoadAllFont() {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700;900&family=Prompt:wght@400;500;600;700;900&&display=swap"]'
    ).length <= 0
  ) {
    let css_link = document.createElement('link');
    css_link.href =
      'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700;900&family=Prompt:wght@400;500;600;700;900&&display=swap';
    css_link.rel = 'stylesheet';
    css_link.type = 'text/css';
    document.head.appendChild(css_link);
  }
}

function LoadFont(text) {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700;900&family=Prompt&display=swap"]'
    ).length <= 0
  ) {
    let notoserif_link = document.createElement('link');
    notoserif_link.href =
      'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700;900&display=swap&text=' +
      encodeURIComponent(text);
    notoserif_link.rel = 'stylesheet';
    notoserif_link.type = 'text/css';
    document.head.appendChild(notoserif_link);

    let yesevaone_link = document.createElement('link');
    yesevaone_link.href =
      'https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700;900&display=swap&text=' +
      encodeURIComponent(text);
    yesevaone_link.rel = 'stylesheet';
    yesevaone_link.type = 'text/css';
    document.head.appendChild(yesevaone_link);
  }
}

Vue.prototype.$GetBodyText = () => {
  return GetBodyText();
};

function GetBodyText() {
  let str = document.body.innerText;
  let uniqueChars = '';
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) !== ' ' && uniqueChars.indexOf(str.charAt(i)) === -1) {
      uniqueChars += str[i];
    }
  }
  uniqueChars = uniqueChars.replace(/\s/g, '');
  str = [...new Set(uniqueChars)].join('');

  str = str.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
    ''
  );
  return str;
}

function CheckFontLoad() {
  const links = document.head.querySelectorAll('link');
  let font_loaded = false;
  links.forEach((link) => {
    link.href.indexOf('css2?family=') != -1 ? (font_loaded = true) : '';
  });
  if (font_loaded) {
    LoadAllFont();
  } else {
    const body_text = GetBodyText();
    body_text != '' ? LoadFont(body_text) : '';
  }
}

Vue.prototype.$CopyLink = async () => {
  document.querySelector('#CopyUrlInput').value = `${window.location.href}`;
  var copyText = document.querySelector('#CopyUrlInput');
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  await navigator.clipboard.writeText(copyText.value);
  alert('已複製連結！');
};

Vue.prototype.$ShareToFB = () => {
  window
    .open(
      `https://www.facebook.com/sharer.php?u=${window.location.href}`,
      '_blank'
    )
    .focus();
};

Vue.prototype.$ShareToLine = () => {
  window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
};
