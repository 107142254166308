import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/esg',
    name: 'esg',
    component: () => import('../views/ESGView.vue'),
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServiceListView.vue'),
  },
  {
    path: '/service/:id',
    name: 'service',
    component: () => import('../views/ServiceView.vue'),
  },
  {
    path: '/projects',
    name: 'project_list',
    component: () => import('../views/ProjectListView.vue'),
  },
  {
    path: '/project/:id',
    name: 'project_page',
    component: () => import('../views/ProjectView.vue'),
  },
  {
    path: '/trivia',
    name: 'trivia_list',
    component: () => import('../views/TriviaListView.vue'),
  },
  {
    path: '/trivia/:id',
    name: 'trivia_page',
    component: () => import('../views/TriviaView.vue'),
  },
  {
    path: '/videos',
    name: 'video_list',
    component: () => import('../views/VideoListView.vue'),
  },
  {
    path: '/video/:id',
    name: 'video_page',
    component: () => import('../views/VideoView.vue'),
  },
  {
    path: '/news',
    name: 'news_list',
    component: () => import('../views/NewsListView.vue'),
  },
  {
    path: '/news/:id',
    name: 'news_page',
    component: () => import('../views/NewsView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      console.log('moving to top of the page');
      window.scrollTo(0, 0);
    }
  },
});

export default router;
