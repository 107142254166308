<template>
  <aside
    ref="MainContent"
    class="fixed top-0 z-30 w-full h-screen p-5 overflow-y-auto left-full bg-primary sm:p-10 pb-14"
  >
    <div
      data-menu-header
      class="sticky top-0 z-10 flex items-center justify-between w-full mb-10 sm:mb-20"
    >
      <router-link to="/" @click.native="Close" aria-label="勁翔空調有限公司">
        <img
          src="/img/logo_white@md.webp"
          width="200"
          height="60"
          alt="勁祥空調有限公司"
          class="w-[200px] sm:block hidden"
        />
        <img
          src="/img/logo_white@sm.webp"
          width="60"
          height="48"
          alt="勁祥空調有限公司"
          class="w-[60px] sm:hidden block"
        />
      </router-link>
      <button
        @click="Close"
        aria-label="關閉選單"
        class="flex items-center justify-center transition-colors duration-300 bg-white w-14 h-14 hover:md:bg-black hover:md:text-white"
      >
        <span class="text-4xl icon-close text-[CurrentColor]"></span>
      </button>
    </div>
    <div class="relative z-0 flex flex-wrap items-stretch w-full">
      <div class="relative z-10 w-full sm:pr-10 sm:w-1/2">
        <ol>
          <li
            v-for="(item, item_index) in menu_list"
            :key="`menu_item_${item_index}`"
            class="mb-5 sm:mb-10"
          >
            <router-link
              data-menu-link
              :to="item.link"
              @click.native="Close"
              class="flex items-end justify-between w-full pb-5 border-b border-white"
            >
              <div>
                <p class="mb-2 text-sm font-bold sm:text-base text-dark-gray">
                  {{ item.sub_title }}
                </p>
                <p class="text-xl font-bold text-white sm:text-2xl">
                  {{ item.title }}
                </p>
              </div>
              <span
                class="flex items-center justify-center w-8 h-8 bg-white sm:w-10 sm:h-10"
              >
                <i class="text-xl icon-arrow text-primary"></i>
              </span>
            </router-link>
          </li>
        </ol>
      </div>
      <div
        class="relative z-10 flex flex-col justify-end w-full overflow-hidden bg-primary sm:px-10 sm:py-10 sm:w-1/2"
      >
        <div class="relative z-10">
          <router-link
            data-menu-link
            to="/contact"
            @click.native="Close"
            class="flex items-end justify-between w-full mb-10"
          >
            <div>
              <p class="mb-2 text-sm font-bold sm:text-base text-dark-gray">
                CONTACT US
              </p>
              <p class="text-xl font-bold text-white sm:text-2xl">聯絡我們</p>
            </div>
            <span
              class="flex items-center justify-center w-8 h-8 bg-white sm:w-10 sm:h-10"
            >
              <i class="text-xl icon-arrow text-primary"></i>
            </span>
          </router-link>
          <a
            data-menu-link
            class="block"
            :href="$GetColumn('company_line')"
            target="_blank"
            aria-label="勁祥空調有限公司LINE@"
          >
            <img
              :src="$ImageUrl($GetColumn('company_line_image'))"
              width="166"
              height="166"
              alt="勁祥空調有限公司LINE@"
              class="block sm:w-40 sm:h-40 w-28 h-28"
            />
          </a>
        </div>
        <img
          data-menu-image
          src="/img/home/homepage_banner_img_1.webp"
          width="370"
          height="740"
          alt="勁祥空調有限公司"
          class="absolute top-0 left-0 z-0 hidden object-cover w-full h-full sm:block mix-blend-overlay"
        />
      </div>
      <!--  -->
    </div>
  </aside>
</template>

<script>
import { MainMenu } from '@/gsap/MainMenu';
export default {
  name: 'MainMenu',
  data() {
    return {
      menu_list: [
        {
          title: '公司介紹',
          sub_title: 'ABOUT US',
          link: '/about',
        },
        {
          title: 'ESG永續經營',
          sub_title: 'ESG SUSTAINABLE',
          link: '/esg',
        },
        {
          title: '服務項目',
          sub_title: 'SERVICE',
          link: '/services',
        },
        {
          title: '工程實績',
          sub_title: 'PROJECTS',
          link: '/projects',
        },
        {
          title: '最新消息',
          sub_title: 'NEWS',
          link: '/news',
        },
        {
          title: '影音相簿',
          sub_title: 'VIDEOS',
          link: '/videos',
        },
        {
          title: '生活花絮',
          sub_title: 'TRIVIA',
          link: '/trivia',
        },
      ],
      menu_status: false,
      menu_gsap: null,
    };
  },
  methods: {
    Open() {
      this.menu_status = true;
    },
    Close() {
      this.menu_status = false;
    },
  },
  watch: {
    menu_status() {
      if (this.menu_status) {
        document.body.classList.add('lock');
        this.menu_gsap.open();
      } else {
        document.body.classList.remove('lock');
        this.menu_gsap.close();
      }
    },
  },
  mounted() {
    this.menu_gsap = new MainMenu(this.$refs.MainContent);
    this.menu_gsap.reset();
  },
};
</script>
