<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full py-24 overflow-hidden md:py-40"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <header class="relative w-full mb-32 text-center">
        <div class="relative z-10 flex flex-col-reverse items-center mb-10">
          <h2
            data-section-subtitle
            class="text-[40px] font-black text-secondary"
          >
            工程實績
          </h2>
          <p
            data-section-title
            class="text-2xl font-bold uppercase text-primary"
          >
            OUR CASE
          </p>
        </div>
        <div
          data-section-content
          class="relative z-10 font-bold text-secondary"
          v-html="
            $GetColumn('our_case_section_content').replace(/\n/g, '<br />')
          "
        ></div>

        <img
          src="/img/home/our_case_text.webp"
          width="1000"
          height="111"
          alt="勁祥空調 核心價值"
          class="absolute sm:block w-auto max-w-full object-contain md:h-[114px] h-[71px] hidden top-0 left-1/2 transform -translate-x-1/2 opacity-5"
        />
        <img
          src="/img/home/our_case_text@sm.webp"
          width="300"
          height="155"
          alt="勁祥空調 核心價值"
          class="absolute sm:hidden block w-[300px] top-0 left-1/2 transform -translate-x-1/2 opacity-5"
        />
      </header>

      <div
        class="flex items-stretch w-full mb-10 transform md:translate-x-[20%] sm:translate-x-[10%]"
      >
        <div
          data-card-box
          v-for="(item, item_index) in case_data"
          :key="`case_${item_index}`"
          class="flex-shrink-0 w-full md:w-3/5 sm:w-4/5"
        >
          <article
            class="flex flex-col-reverse w-full px-3 transform md:px-10 sm:px-5"
          >
            <div class="flex-1">
              <h4
                data-card-title
                class="mb-2 text-xl font-black truncate sm:text-2xl text-primary"
              >
                {{ item.Title }}
              </h4>
              <div class="flex items-center">
                <p class="mr-3 text-sm font-medium text-dark-gray">
                  {{ item.created_at.slice(0, 10) }}
                </p>
                <p
                  class="px-1 text-sm font-medium text-dark-gray bg-light-gray"
                >
                  {{ item.CategoryTitle }}
                </p>
              </div>
            </div>
            <router-link
              aria-label="item.Title"
              :to="`/project/${item.ProjectID}`"
              class="w-full relative z-10 aspect-[3/2] mb-5"
            >
              <div data-card-button class="absolute bottom-0 right-0 z-10">
                <div
                  class="z-10 flex items-center justify-center w-12 h-12 text-white transition-all duration-300 transform sm:w-14 sm:h-14 bg-primary hover:md:bg-light-gray hover:md:text-primary"
                >
                  <span class="text-2xl text-current icon-arrow"></span>
                </div>
              </div>
              <img
                data-card-image
                :src="$ImageUrl(item.Image2)"
                :alt="item.Title"
                width="600"
                height="400"
                class="relative z-0 block object-cover w-full h-full"
              />
            </router-link>
          </article>
        </div>
      </div>
      <div
        class="px-[10px] w-full max-w-[90%] mx-auto items-end flex justify-center mb-10"
      >
        <ol class="flex items-center">
          <li
            v-for="(bullet, bullet_index) in case_data"
            :key="`sm_bullet_${bullet_index}`"
            class="px-2"
          >
            <button
              data-card-bullet
              @click="ChangeCarousel(bullet_index)"
              :aria-label="`切換至第${bullet_index}張`"
              class="w-2 h-2"
            ></button>
          </li>
        </ol>
      </div>
      <div class="flex items-center justify-center w-full">
        <router-link
          data-section-button
          to="/projects"
          class="md:w-[600px] sm:w-[531px] w-full font-bold p-5 bg-light-gray text-primary inline-block hover:md:text-white hover:md:bg-primary transition-colors duration-200"
        >
          <span
            class="inline-block w-2 h-2 mr-4 text-current bg-current"
          ></span>
          VIEW ALL
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { CaseList } from '@/gsap/home/CaseList';
export default {
  name: 'HomeCaseListSection',
  data() {
    return {
      case_list_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.case_list_gsap.setup();
    },
    ChangeCarousel(index) {
      this.case_list_gsap.changeCarousel(index);
    },
  },
  computed: {
    case_data() {
      return this.$store.state.project_data;
    },
  },
  mounted() {
    this.case_list_gsap = new CaseList(this.$refs.MainContent);
    this.case_list_gsap.reset();
  },
};
</script>
