<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full py-24 overflow-hidden md:py-40"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <header class="relative w-full mb-32 text-center">
        <div class="relative z-10 flex flex-col-reverse items-center mb-10">
          <h2
            data-section-subtitle
            class="text-[40px] font-black text-secondary"
          >
            核心價值
          </h2>
          <p
            data-section-title
            class="text-2xl font-bold uppercase text-primary"
          >
            CORE VALUE
          </p>
        </div>
        <div
          data-section-content
          class="relative z-10 font-bold text-secondary"
          v-html="
            $GetColumn('core_value_section_content').replace(/\n/g, '<br />')
          "
        ></div>

        <img
          src="/img/home/core_value_text.webp"
          width="1000"
          height="111"
          alt="勁祥空調 核心價值"
          class="absolute sm:block w-auto lg:max-w-[1000px] max-w-full object-contain h-[114px] hidden top-0 left-1/2 transform -translate-x-1/2 opacity-5"
        />
        <img
          src="/img/home/core_value_text@sm.webp"
          width="300"
          height="155"
          alt="勁祥空調 核心價值"
          class="absolute sm:hidden block w-[300px] top-0 left-1/2 transform -translate-x-1/2 opacity-5"
        />
      </header>

      <ol data-card-list class="flex flex-wrap items-stretch mb-10 -mx-10">
        <li
          v-for="(item, item_index) in core_value_data"
          :key="`core_value_${item_index}`"
          class="w-full px-10 mb-10 md:w-1/2 md:mb-20"
          data-card-box
        >
          <div class="flex items-end">
            <h3
              data-card-title
              class="mr-3 text-2xl font-black sm:text-3xl text-secondary"
            >
              {{ item.Title }}
            </h3>
            <div class="flex-1">
              <i
                data-card-line
                class="block w-full h-[1px] bg-primary mb-1"
              ></i>
              <p
                data-card-subtitle
                class="text-sm font-black uppercase text-primary sm:text-base"
              >
                {{ item.SubTitle }}
              </p>
            </div>
          </div>
          <div
            class="relative z-10 flex items-start justify-between w-full pt-5 sm:pt-10"
          >
            <p
              data-card-number
              class="absolute sm:text-[160px] text-[120px] tracking-wider font-bold text-primary opacity-5 top-1/2 right-0 transform -translate-y-1/2 z-0"
            >
              {{
                item_index + 1 < 10 ? '0' + (item_index + 1) : item_index + 1
              }}
            </p>
            <p
              data-card-content
              class="relative z-10 flex-1 text-sm font-bold text-secondary sm:text-base"
            >
              {{ item.Content }}
            </p>
            <img
              data-card-image
              :src="item.Image"
              :alt="item.Title"
              width="80"
              height="80"
              class="relative z-10 flex-shrink-0 w-20 ml-10"
            />
          </div>
        </li>
      </ol>

      <!-- <div class="flex items-center justify-center w-full">
        <router-link
          data-section-button
          to="/core_value"
          class="md:w-[600px] sm:w-[531px] w-full font-bold p-5 bg-light-gray text-primary inline-block hover:md:text-white hover:md:bg-primary transition-colors duration-200"
        >
          <span class="inline-block w-2 h-2 bg-[CurrentColor] mr-4"></span>
          LEARN MORE
        </router-link>
      </div> -->
    </div>
  </section>
</template>

<script>
import { CoreValue } from '@/gsap/home/CoreValue';
export default {
  name: 'HomeCoreValueSection',
  data() {
    return {
      core_value_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.core_value_gsap.setup();
    },
  },
  computed: {
    core_value_data() {
      return [
        {
          Title: this.$GetColumn('core_value_1_title'),
          SubTitle: this.$GetColumn('core_value_1_sub_title'),
          Content: this.$GetColumn('core_value_1_content'),
          Image: this.$ImageUrl(this.$GetColumn('core_value_1_image')),
        },
        {
          Title: this.$GetColumn('core_value_2_title'),
          SubTitle: this.$GetColumn('core_value_2_sub_title'),
          Content: this.$GetColumn('core_value_2_content'),
          Image: this.$ImageUrl(this.$GetColumn('core_value_2_image')),
        },
        {
          Title: this.$GetColumn('core_value_3_title'),
          SubTitle: this.$GetColumn('core_value_3_sub_title'),
          Content: this.$GetColumn('core_value_3_content'),
          Image: this.$ImageUrl(this.$GetColumn('core_value_3_image')),
        },
        {
          Title: this.$GetColumn('core_value_4_title'),
          SubTitle: this.$GetColumn('core_value_4_sub_title'),
          Content: this.$GetColumn('core_value_4_content'),
          Image: this.$ImageUrl(this.$GetColumn('core_value_4_image')),
        },
      ];
    },
  },
  mounted() {
    this.core_value_gsap = new CoreValue(this.$refs.MainContent);
    this.core_value_gsap.reset();
  },
};
</script>
