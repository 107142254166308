import Vue from 'vue';
import Vuex from 'vuex';
import { getAllData } from '@/api/page';

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    main_dialog: {
      status: false, //系統訊息顯示狀態
      content: '', //系統訊息內容
    },
    image_loaded: false, //頁面圖片讀取狀態
    loading: 0, //Loading狀態，大於0則為讀取中，等於0則為讀取完畢
    common_column_data: null, //通用欄位
    carousel_data: null, //首頁倫波圖
    news_data: null, //最新消息
    news_category_data: null, //最新消息分類
    project_data: null, //工程實績
    project_category_data: null, //工程實績分類
    history_data: null, //公司沿革
    history_category_data: null, //公司沿革年份分類
    collaborate_data: null, //合作企業
    license_image_data: null, //相關證書
    labor_safety_data: null, //勞安項目
    labor_safety_image_data: null, //勞安器具
    service_data: null, //服務項目
    service_category_data: null, //服務項目分類
    trivia_data: null, //生活花絮
    trivia_category_data: null, //生活花絮分類
    video_data: null, //影音相簿
    video_category_data: null, //影音相簿分類
  },
  getters: {
    all_data_loaded(state) {
      const key_list = [
        'common_column_data',
        'carousel_data',
        'news_data',
        'news_category_data',
        'project_data',
        'project_category_data',
        'history_data',
        'history_category_data',
        'collaborate_data',
        'license_image_data',
        'labor_safety_data',
        'labor_safety_image_data',
        'service_data',
        'service_category_data',
        'trivia_data',
        'trivia_category_data',
        'video_data',
        'video_category_data',
      ];

      let error = false;
      key_list.forEach((key) => {
        state[key] == null ? (error = true) : '';
      });
      return !error;
    },
  },
  mutations: {
    // 設定頁面圖片讀取狀態
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    // 設定Loading狀態
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : '';
      }
    },
    // 設定Dialog狀態與內容文字
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    // 設定State資料
    SetState(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData({ commit }) {
      getAllData().then((res) => {
        // 輪播圖
        commit('SetState', {
          key: 'carousel_data',
          val: res.data.Carousel.data,
        });
        // 最新消息
        commit('SetState', {
          key: 'news_data',
          val: res.data.News.data,
        });
        commit('SetState', {
          key: 'news_category_data',
          val: res.data.NewsCategory.data,
        });
        // 工程實績
        commit('SetState', {
          key: 'project_data',
          val: res.data.Project.data,
        });
        commit('SetState', {
          key: 'project_category_data',
          val: res.data.ProjectCategory.data,
        });
        // 公司沿革
        commit('SetState', {
          key: 'history_data',
          val: res.data.History.data,
        });
        commit('SetState', {
          key: 'history_category_data',
          val: res.data.HistoryCategory.data,
        });
        // 通用欄位
        commit('SetState', {
          key: 'common_column_data',
          val: res.data.Column.data,
        });
        // 合作企業
        commit('SetState', {
          key: 'collaborate_data',
          val: res.data.Collaborate.data,
        });
        // 相關證照
        commit('SetState', {
          key: 'license_image_data',
          val: res.data.LicenseImage.data,
        });
        // 勞安項目
        commit('SetState', {
          key: 'labor_safety_data',
          val: res.data.LaborSafety.data,
        });
        commit('SetState', {
          key: 'labor_safety_image_data',
          val: res.data.LaborSafetyImage.data,
        });
        // 服務項目
        commit('SetState', {
          key: 'service_data',
          val: res.data.Service.data,
        });
        commit('SetState', {
          key: 'service_category_data',
          val: res.data.ServiceCategory.data,
        });
        // 生活花絮
        commit('SetState', {
          key: 'trivia_data',
          val: res.data.Trivia.data,
        });
        commit('SetState', {
          key: 'trivia_category_data',
          val: res.data.TriviaCategory.data,
        });
        // 影音相簿
        commit('SetState', {
          key: 'video_data',
          val: res.data.Video.data,
        });
        commit('SetState', {
          key: 'video_category_data',
          val: res.data.VideoCategory.data,
        });
      });
    },
  },
});

export default store;
