import { gsap } from '@/gsap/GsapLoader';

export class MainMenu {
  constructor(el) {
    this.el = el;
    this.image_box = el.querySelector('[data-menu-image]');
    this.links = el.querySelectorAll('[data-menu-link]');
    this.header = el.querySelector('[data-menu-header]');

    this.timeline = null;
  }

  reset() {
    gsap.set(this.el, { opacity: 0, x: '0%', blur: 10 });
    gsap.set(this.image_box, { x: '-100%' });
    gsap.set(this.links, { opacity: 0, x: '-10px' });
    gsap.set(this.header, { opacity: 0, y: '-10px' });
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline.set(this.el, { x: '-100%' }, 'before_open');
    this.timeline.fromTo(
      this.el,
      {
        opacity: 0,
        blur: 10,
      },
      {
        opacity: 1,
        blur: 0,
      },
      'open'
    );
    this.timeline.fromTo(
      this.header,
      {
        opacity: 0,
        y: '-10px',
      },
      {
        opacity: 1,
        y: '0px',
      },
      'first_show'
    );
    this.timeline.fromTo(
      this.image_box,
      {
        x: '-100%',
      },
      {
        x: '0%',
        delay: 0.2,
      },
      'first_show'
    );
    this.timeline.fromTo(
      this.links,
      {
        opacity: 0,
        x: '-10px',
      },
      {
        opacity: 1,
        x: '0px',
        delay: 0.4,
      },
      'first_show'
    );
  }

  close() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline.fromTo(
      this.header,
      {
        opacity: 1,
        y: '0px',
      },
      {
        opacity: 0,
        y: '-10px',
      },
      'first_show'
    );

    this.timeline.fromTo(
      this.links,
      {
        opacity: 1,
        x: '0px',
      },
      {
        opacity: 0,
        x: '-10px',
      },
      'first_show'
    );

    // this.timeline.fromTo(
    //     this.image_box,
    //     {
    //       scaleX: 0,
    //       transformOrigin: 'left',
    //     },
    //     {
    //       scaleX: 1,
    //       transformOrigin: 'left',
    //     },
    //     'first_show'
    //   );

    this.timeline.fromTo(
      this.el,
      {
        opacity: 1,
        blur: 0,
      },
      {
        opacity: 0,
        blur: 10,
        delay: 0.3,
      },
      'first_show'
    );
    this.timeline.set(this.el, { x: '0%' }, 'after_open');
  }
}
